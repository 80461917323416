<template>
  <div class="w1092 pt60">
    <div class="board">
      <div class="boardrd first">
        <span class="nicon ignbg ml10">일반</span>
        <span class="ml10"># 게임공지 #</span>
      </div>
      <div class="boardrd">
        <span>win</span><span class="ml10">2025.04.02 14:23</span>
      </div>
      <div class="boardbox">
        <pre>
@에볼루션금지게임목록@

TV쇼 게임 카테고리 전게임
룰렛전게임
바카라훔쳐보기
1인칭 풍성한 골든바카라
슈퍼 내부외부
판탄
위 게임은 게임하여 당첨이 되더라도 베팅금 당첨금 모두 몰수처리됩니다
        </pre>
      </div>
    </div>

    <div class="boardbtn">
      <!-- <a class="blsbtn" v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a> -->
      <a class="blsbtn" @click="goPageByName('noticeList')">{{$t('front.board.list')}}</a>
      <!-- <a class="blsbtn" v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'read1',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
